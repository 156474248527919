<template>
  <span v-if="!loading">

    <a v-if="item.agent && Object.keys(item.agent).length > 0" :href="'/agent/'+item.agent.agent_id+'/main'" class="float-left mr-8">
      <v-icon>mdi-arrow-up-left</v-icon> Agentti {{ item.agent.name }}
    </a>

    <!-- header -->
    <h1 class="headline white--text text-center mb-3" v-if="item.id">Chat alias: {{ item.name }}</h1>
    <h1 class="headline white--text" v-else>Luo uusi chat-alias</h1>

    <!-- navigation tabs -->
    <v-tabs
        v-if="item.id"
        hide-slider
        show-arrows
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab
          v-for="tab of pageTabs"
          :key="tab.id"
          :to="'/chat_alias/'+item.id+'/'+tab.id"
      >
        {{tab.name}}
        <span v-if="item[tab.count]">({{ item[tab.count] }})</span>
      </v-tab>
    </v-tabs>

    <!-- Main form -->
    <div v-if="page == 'main' || page == 'new'" class="sub-page">

      <h1 class="headline blue--text text-center mt-4 mb-3">Perustiedot</h1>

      <v-card
          v-if="item.active || item.id"
      >
        <v-card-text align="right">
          <v-btn
              v-if="item.active"
            @click="deactivateWithReason"
            color="red"
            small
          >
            Deaktivoi odottamaan agentin korjauksia
          </v-btn>
          <v-btn
              v-else-if="item.id"
              @click="activate"
              color="green"
              small
          >
            Aktivoi alias
          </v-btn>
        </v-card-text>
      </v-card>

      <!-- form segment -->
      <v-form
          ref="form"
      >
        <!-- formFields loop -->
        <span v-for="data in formFields" :key='data.item_id'>

          <!-- show chips when needed -->
          <v-chip-group
              v-if="data.chips"
              column
          >
            <!-- single chip -->
            <v-chip
                color="blue"
                label
                class="darken-4"
                v-if="!data.multiple && item[data.item_id] && Object.keys(item[data.item_id]).length > 0"
                :close="data.chips_close"
                @click:close="chipsClose(data, item[data.item_id])"
                :large="data.chips_large"
            >
              <router-link :to="'/agent/'+item[data.item_id].agent_id+'/main'">{{ data.chips_name ? item[data.item_id][data.chips_name] : item[data.item_id].id }}</router-link>
            </v-chip>
            <!-- multiple chips -->
            <v-chip v-else
                    color="blue"
                    label
                    class="darken-4"
                    v-for="chip in item[data.item_id]"
                    :key="'chip'+chip.id"
                    :close="data.chips_close"
                    @click:close="chipsClose(data, chip)"
            >
              {{ data.chips_name ? chip[data.chips_name] : chip.name }}
            </v-chip>
          </v-chip-group>

          <!-- anonymous component to handle form stuff -->
          <component
              :is="data.component"
              v-model="item[data.item_id]"
              :items="searchItems[data.item_id]"
              :label="data.label"
              :hint="data.hint"
              :persistent-hint="data.persistent_hint"
              :search-input.sync="searchInputs[data.item_id]"
              :loading="searchLoading[data.item_id]"
              :item-text="data.item_text"
              :item-value="data.item_value"
              :no-data-text="data.no_data_text"
              :multiple="data.multiple"
              :chips="data.chips"
              :return-object="data.return_object"
              :background-color="data.background_color"
              :rules="data.rules"
              :counter="data.counter"
              :class="data.class"
              :mandatory="data.mandatory"
              hide-no-data
              @focus="componentFocus(data)"
          >

            <!-- disable selection, using external selection system -->
            <template v-slot:selection></template>

            <template v-slot:item="data">
              {{ data.item.name }}
            </template>
            <template v-if="data.v_html">
              {{ data.v_html }}
            </template>
            <template v-if="data.radios">
                <v-radio
                    :value="1"
                    label="viihde"
                    :disabled="disableTypeViihde"
                ></v-radio>
                <v-radio
                    :value="2"
                    label="asiantuntija"
                    :disabled="disableTypeAsiantuntija"
                ></v-radio>
            </template>
          </component>

        </span>

        <!--  submit buttons -->
        <v-row>
          <v-col>
            <v-btn class="button green" @click="submit">
              <template v-if="item.id">Päivitä tiedot</template>
              <template v-else>Lisää uusi chat alias</template>
            </v-btn>
          </v-col>
          <v-col
              v-if="item.id"
              aligh="right">
            <v-btn
                class="button red"
                @click="deleteAlias"
            >
              Poista tämä alias
            </v-btn>
          </v-col>
        </v-row>


      </v-form>

    </div>


    <!-- Media form -->
    <span v-if="page == 'media'">


      <h1 class="headline blue--text text-center mt-4 mb-3">Kuvat ja videot</h1>
      <v-divider></v-divider>

      <alias-media
          v-if="item.alias_media"
          :id="item.id"
          :items="item.alias_media"
          @alias_media="item.alias_media.push($event)"
          @remove_alias_media="item.alias_media.splice(item.alias_media.findIndex(x => x.id == $event), 1)"
      ></alias-media>

    </span>




    <!-- Additional introductions (esittelyt) -->
    <span v-if="page == 'alias_descriptions'">

      <h1 class="headline blue--text text-center mt-4 mb-3">Lisäesittelyt</h1>
      <v-divider></v-divider>

      <v-row>
        <v-col>
          <v-btn
              class="v-btn ma-3 green float-right"
              @click="edit_item = {featureId: 8}; dialog=true"
              small
          >
            <v-icon>mdi-plus-circle-outline</v-icon> Lisää lisäesittely
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
          v-if="item.alias_descriptions && item.alias_descriptions.length > 0"
          :hide-default-footer="true"
          :headers="[
      {
        text: 'Esittely',
        value: 'description',
      },
      {
        text: 'toiminnot',
        value: 'edit',
      },
    ]"
          :items="item.alias_descriptions"
      >
        <template v-slot:item.edit="{item}">
          <v-btn x-small class="v-btn green" @click="edit_item = item; dialog=true;">muokkaa</v-btn>
          <v-btn x-small class="v-btn red ml-2" @click="edit_item = item;confirmation = true">poista</v-btn>
        </template>
</v-data-table>

      <v-alert
          v-if="!item.alias_descriptions || item.alias_descriptions.length == 0"
          class="red darken-4 mt-5"
      >
        Aliaksella ei ole lisäesittelyjä
      </v-alert>

      <v-dialog
          v-model="dialog"
          width="600"
          @click:outside="edit_item = {}"
      >
        <v-card>
          <v-card-title v-if="edit_item.id">
            Muokkaa
          </v-card-title>
          <v-card-title v-else>
            Uusi lisäesittely
          </v-card-title>
          <v-card-text>
            <v-textarea
                v-model="edit_item.description"
                rows="8"
                placeholder="Kirjoita esittely"
                outlined
            ></v-textarea>

            <!-- select net_product_type_id -->
            <v-select
                v-model="edit_item.featureId"
                :items="[
                    {featureId: 6, name: 'Ennustus asiantuntija'},
                    {featureId: 8, name: 'Verkkotilaukset asiantuntija'},
                    {featureId: 9, name: 'Neuvonta asiantuntija'}
                ]"
                item-value="featureId"
                item-text="name"
                label="Ominaisuus"
            >
              <template v-slot:item="data">
                {{ data.item.name }}
              </template>
            </v-select>

            <v-btn class="v-btn green" @click="submitFeature" :disabled="loading">Tallenna</v-btn>

          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- confirmation -->
      <v-dialog
          v-model="confirmation"
          max-width="600"
      >
        <v-alert
            color="red darken-3"
        >
          Poistetaanko esittely {{ edit_item.id }}?
          <v-btn class="v-btn red ml-2" @click="deleteFeature(edit_item)">poista</v-btn>
        </v-alert>
      </v-dialog>

    </span>

    <!-- not active reason  -->
    <v-dialog
        v-model="dialogs.not_active_reason"
        max-width="600">
      <v-alert
          color="red darken-3"
      >
        Poista aliaksen aktivointi syyn kera:
      </v-alert>
    </v-dialog>

    <!-- snackbar for instant messages -->
    <v-snackbar
        v-model="snackbar"
        :timeout="snackbar_timeout"
        color="green"
    >
      {{ snackbar_message }}
    </v-snackbar>

  </span>

  <!-- loading -->
  <v-skeleton-loader
      v-else
      class="mx-auto"
      type="card, article, actions"
  >
  </v-skeleton-loader>
</template>

<script>
import GlobalVars from "../mixins/GlobalVars";

import AliasMedia from "../components/AliasMedia";

//manually import vuetify form objects for dynamic usage
import {
  VTextField,
  VTextarea,
  VChipGroup,
  VImg,
  VAutocomplete,
  VCheckbox,
  VSubheader,
  VDivider,
  VContainer,
  VSnackbar,
  VRadioGroup,
  VRadio

} from 'vuetify/lib'

export default {
  name: "ChatAlias",
  mixins: [
    GlobalVars,
  ], //import default set of values
  components: {
    VTextField,
    VChipGroup,
    VImg,
    VCheckbox,
    VTextarea,
    VAutocomplete,
    VSubheader,
    VDivider,
    VContainer,
    VSnackbar,
    VRadioGroup,
    VRadio,
    AliasMedia,
  },
  props: [
    'id',
    'page',
    'agent_id'
  ],
  data: function () {
    return {
      route: 'chat_aliases/', //change to read from env variable
      item: {
        type: 1,
        agent: {}
      },
      pageTabs: [
        { id: 'main', name: 'perustiedot'},
        {
          id: 'media',
          name: 'Kuvat ja videot',
          count: 'alias_media_count'
        },
        {
          id: 'alias_descriptions',
          name: 'Lisäesittelyt',
          count: 'alias_descriptions_count'
        },
      ],
      formFields: [
        {
          component: 'v-divider',
        },
        {
          item_id: 'agent',
          label: 'Kuuluu agentille (hae nimellä)',
          hint: 'Kuka agentti käyttää tätä aliasta',
          persistent_hint: false,
          component: 'v-autocomplete',
          multiple: false,
          chips: true,
          chips_name: 'name',
          return_object: true,
          item_text: "name",
          item_value: "id",
        },
        {
          item_id: 'type',
          label: 'Aliaksen tyyppi',
          component: 'v-radio-group',
          mandatory: false,
          disabled: "agent",
          radios: [
            {
              key: 1,
              value: 1,
              label: 'aikuisviihde',
            },
            {
              key: 2,
              value: 2,
              label: 'asiantuntija',
            },
          ]
        },
        {
          item_id: 'name',
          label: 'Nimi',
          hint: null,
          component: 'v-text-field',
          counter: 45,
          rules: [(v) => !!v && v.length <= 45 || '1-45 kirjainta'],
        },
        {
          item_id: 'public_id',
          label: 'Public ID',
          hint: 'Aliaksen tunniste URL-osoitteessa. Ei isoja kirjaimia, välilyöntejä, ääkkösiä tai muita erikoismerkkejä. Ei voi olla kahta saman tyypin aliasta, jolla on sama public_id. Muuta tätä vain jos on ehdottomasti pakko',
          persistent_hint: true,
          class: 'mb-3',
          component: 'v-text-field',
          counter: 40,
          rules: [(v) => !!v && v.length <= 40 || 'On oltava täytetty. Maksimipituus 40 merkkiä. Ei välilyöntejä eikä ääkkösiä'],
        },
        {
          item_id: 'description',
          label: 'Kuvaus',
          hint: null,
          background_color: "#4c4d4c",
          component: 'v-textarea'
        },
        {
          item_id: 'active',
          label: 'Aktiivinen alias',
          hint: null,
          component: 'v-checkbox'
        },
        {
          item_id: 'free_chat_enabled',
          label: 'Voi käynnistää ilmaisen chattin',
          hint: 'Vain harvoille ja valituille aliaksille mahdollistettu toiminto, jolla voi työasemalla aikatauluttaa ja avata chat-huoneita, jossa asiakkaat voi chatata ilmaiseksi',
          persistent_hint: true,
          component: 'v-checkbox'
        },
        {
          component: 'v-divider',
        },

        {
          item_id: 'categories',
          label: 'Kategorioissa',
          hint: 'Alias kuuluu näihin kategorioihin',
          persistent_hint: true,
          component: 'v-autocomplete',
          multiple: true,
          chips: true,
          return_object: true,
          item_text: "name",
          item_value: "id",
          no_data_text: "Hakusanalla ei löytynyt mitään",
          on_focus: 'categories',
          chips_close: true,
          chips_close_action: true,
          chips_close_message: 'kategoria poistettiin',
        },
        {
          item_id: 'sub_categories',
          label: 'Alikategorioissa',
          hint: 'Alias kuuluu näihin alikategorioihin',
          persistent_hint: true,
          component: 'v-autocomplete',
          multiple: true,
          chips: true,
          return_object: true,
          item_text: "name",
          item_value: "id",
          no_data_text: "Hakusanalla ei löytynyt mitään",
          on_focus: 'sub_categories',
          chips_close: true,
          chips_close_action: true,
          chips_close_message: 'alikategoria poistettiin',
        },
        {
          component: 'v-divider',
        },

      ],
      searchItems: {
        categories: null,
        sub_categories: null,
        agent: null,
      },
      searchInputs: {
        categories: "",
        sub_categories: "",
        agent: "",
      },
      searchLoading: {
        categories: false,
        sub_categories: false,
        agent: false,
      },
      dialog: false,
      confirmation: false,
      dialogs: {
        'not_active_reason': false,
      }, //array of dialogs (replace dialog and confirmation)
      edit_item: {
        featureId: 8
      },
      disableTypeViihde: true,
      disableTypeAsiantuntija: true,
    }
  },
  watch: {
    agent_id(){

      if(this.agent_id){
        console.log('agent_id'+this.agent_id)

        this.axios.get('/agents/'+this.agent_id).then((response) => {
              this.item.agent = response.data
            })
      }
    },
    page(){

      if(this.page == 'new'){

        this.item = {}
        if(this.$refs.form) this.$refs.form.reset()
      }
    },
    id(){

      this.$refs.form.reset()

      if(this.id) this.apiGet(this.route+this.id)

    },
    "searchInputs.categories"(){

      if(this.searchInputs.categories) {
        clearTimeout(this.search_timeout)
        this.search_timeout = setTimeout(() => {
          this.getSearchItems('categories', 'categories', this.searchInputs.categories)
        }, 500)
      }

    },
    "searchInputs.sub_categories"(){

      if(this.searchInputs.sub_categories) {
        clearTimeout(this.search_timeout)
        this.search_timeout = setTimeout(() => {
          this.getSearchItems('sub_categories', 'sub_categories', this.searchInputs.sub_categories)
        }, 500)
      }

    },
    "searchInputs.agent"(){

      if(this.searchInputs.agent && this.searchInputs.agent != "") {
        clearTimeout(this.search_timeout)
        this.search_timeout = setTimeout(() => {
          this.getSearchItems('agent', 'agents', this.searchInputs.agent, '&active=1')
        }, 500)
      }

    },

    //when agent is changed
    "item.agent"(){
      //this.item.type = null
      this.disableTypeViihde = this.disableByFeature(1)
      this.disableTypeAsiantuntija = this.disableByFeature(2)

      if(this.disableTypeViihde && this.item.type == 1) this.item.type = null
      if(this.disableTypeAsiantuntija && this.item.type == 2) this.item.type = null
    }

  },
  methods: {

    activate(){

      this.axios.post('agents/'+this.item.agent_id+'/chat_alias_activation/', {aliases:[this.item.id]}).then(()=>{
        this.item.active = true
        this.item.not_active_reason_id = null
        this.snackbar = true
        this.snackbar_message = "Alias on aktivoitu"
      })

    },
    deactivateWithReason(){

      //post agent/{id}/chat_alias_violation
      this.axios.post('agents/'+this.item.agent_id+'/chat_alias_violation/', {aliases:[this.item.id]}).then(()=>{
        this.item.active = false
        this.item.not_active_reason_id = 2
        this.item.not_active_reason = {id: 2}

        this.snackbar = true
        this.snackbar_message = "Alias on deaktivoitu ja syyksi on merkitty että korjauksia vaaditaan"
      })

    },
    submitFeature(){

      var method = 'post'
      if(!this.edit_item.alias_id) this.edit_item.aliasId = this.item.id

      this.edit_item.domainId = 4

      if(this.edit_item.id) method = 'put'
      //chat_alias/{id}/extra_descriptions
      this.axios[method]('chat_aliases/'+this.id+'/extra_descriptions/'+(this.edit_item.id ? this.edit_item.id : ''), this.edit_item).then((response)=>{

        this.dialog = false

        if(method == 'post'){
          this.snackbar = true
          this.snackbar_message = "Lisätty uusi lisäesittely"
          this.item.alias_descriptions = [response.data]
        }else{
          this.snackbar = true
          this.snackbar_message = "Lisäesittely on päivitetty"
        }
      })

    },
    deleteFeature(item){

      this.axios.delete('chat_aliases/'+this.id+'/extra_descriptions/'+item.id).then(()=>{

        this.item.alias_descriptions.splice(this.item.alias_descriptions.findIndex(x => x.id == item.id), 1)
        this.confirmation = false

        this.snackbar = true
        this.snackbar_message = "esittely poistettu"

      }).catch((e) => {
        console.log(e)
        this.loading = false
      });

    },
    componentFocus(data){

      if(data.on_focus == 'categories') this.getSearchItems('categories', 'categories', this.searchInputs.categories)
      if(data.on_focus == 'sub_categories') this.getSearchItems('sub_categories', 'sub_categories', '')
    },
    disableComponent(data){
      if(data.disabled == 'agent' && Object.keys(this.item.agent).length == 0) return true;
    },
    disableByFeature(feature){
      if(typeof this.item?.agent?.features == 'undefined') return true;

      if(Object.keys(this.item.agent).length > 0 && feature == 1) {
        if(this.item.agent.features.find(x => x.id == 1) || this.item.agent.features.find(x => x.id == 3)){
          return false;
        }
      }
      if(Object.keys(this.item.agent).length  > 0 && feature == 2) {
        if(this.item.agent.features.find(x => x.id === 2) || this.item.agent.features.find(x => x.id === 4)){
          return false;
        }
      }

      return true
    },

    submit(){

      if(!this.$refs.form.validate()) {
        this.error = this.$t('errors in form')
        return //validate form
      }

      if(this.loading) return
      this.loading = true

      var method = 'post' //to create new alias
      var route = 'chat_aliases/'

      if(this.item.id){
        route += this.item.id //to update alias
        method = 'put' //to update alias
      }

      this.axios[method](route,this.item).then((response)=>{
        console.log(response)
        this.loading = false

        this.snackbar_message = "Uusi alias luotu / päivitetty"
        this.snackbar = true

        //if post, make return id and store item id to this.item
        if(response.data) this.item = response.data
        if(!this.item.categories) this.item.categories = []
        if(!this.item.sub_categories) this.item.sub_categories = []
        if(!this.item.alias_media) this.item.alias_media = []

        //emit item for an update for other instances
        this.$root.$emit('aliases_table_chat_aliases', this.item)

      }).catch((e) => {
        console.log(e)
        this.loading = false
      });


    },

    deleteAlias(){

      var route = 'chat_aliases/'

      this.axios.delete(route+this.item.id).then(()=>{

        this.$root.$emit('aliases_delete', this.item.id)

        this.item = {}
        this.snackbar_message = "alias poistettu"
        this.snackbar = true

      }).catch((e) => {
        console.log(e)
        this.loading = false
      });

    }

  },
  mounted(){

    if(this.id) this.apiGet(this.route+this.id)

    if(this.agent_id){
      console.log('agent_id'+this.agent_id)
      this.axios.get('/agents/'+this.agent_id).then((response) => {
        this.item.agent = response.data
        this.disableTypeViihde = this.disableByFeature(1)
        this.disableTypeAsiantuntija = this.disableByFeature(2)
      })
    }

    //only update agent activity
    this.$root.$on('chat_alias', function(payload){

      //console.log('$on chat_alias', payload)

      //if item is payload id, only set active to whatever it is defined
      if(this.item.id == payload.id) this.item.active = payload.active

    }.bind(this))

  }
}
</script>
